:root {
  --background: #fbfbfb;
  --white: #ffffff;
  --brand-900: rgba(0, 22, 98, 1);
  --kio-900: #bc661f;
  --kio-800: #e77e26;
  --kio-200: #e77e261a;
  --kio-100: #e77e260d;
  --icon-hover: var(--chakra-colors-kio-400);
  --icon-active: var(--chakra-colors-kio-900);
  --background-hover: var(--kio-100);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root:empty {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.1);
  background-image: url(/public/assets/images/loader.gif);
  background-position: center;
  background-repeat: no-repeat;
}

button {
  outline: none;
  appearance: none;
}

code {
  font-family: "Rubik", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pointer {
  cursor: pointer;
}

/* FIXED BOTTOM */
.fixed-bottom {
  position: fixed;
  bottom: 0;
  /* padding: 1rem; */
  width: 100%;
  background-color: #fff;
}

/* SLICK */
.slick-next::before,
.slick-prev::before {
  color: gray !important;
}

/* STEPS */
.register_steps > div > div[aria-current="step"]:first-child {
  background: var(--kio-800);
  border-color: var(--kio-800);
  color: white;
}

.register_steps > div > div > span {
  font-size: 12px;
  overflow: visible;
  word-break: keep-all;
  width: max-content;
}

.string-break {
  -ms-word-break: break-all;
  word-break: break-all;
  white-space: pre-wrap;
}

.steps-chevron {
  position: relative;
}
.steps-chevron::after {
  content: "";
  position: absolute;
  border: 23px solid transparent;
  border-left-color: #ddd;
  top: 0px;
  right: -45px;
}

.ellipse-2 {
  background-color: var(--white);
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__input-container input {
  height: 33px;
  width: inherit;
  border: 1px solid #e2e8f0;
  font-size: 12px;
  border-radius: 0.125rem;
  padding-left: 10px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0.9);
}

/* PWA specific */

@media (display-mode: standalone) {
  button,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    user-select: none;
    -webkit-user-select: none;
  }
}

a,
a:focus {
  outline: none;
}

* {
  scrollbar-width: none;
}

.allow-scroll {
  scrollbar-width: unset;
}

.allow-scroll::-webkit-scrollbar {
  display: unset;
}

::-webkit-scrollbar {
  display: none;
}

.scrollable-panel {
  overflow-y: hidden;
  overflow-x: auto;
}

/* Table & list */

li {
  line-height: 1.5;
}

thead > tr {
  background-color: rgb(230, 230, 230);
}

tbody > tr:nth-child(even) {
  background-color: rgb(245, 245, 245);
}

tbody > tr:nth-child(odd) {
  background-color: rgb(255, 255, 255);
}

/* Table with first column and header fixed */

table.sticky-column {
  position: relative;
}

table.sticky-column thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

table.sticky-column thead tr th:first-of-type {
  background-color: rgb(230, 230, 230);
}

table.sticky-column tbody tr:nth-child(odd) td:first-of-type {
  background-color: rgb(255, 255, 255);
}

table.sticky-column tbody tr:nth-child(even) td:first-of-type {
  background-color: rgb(245, 245, 245);
}

table.sticky-column :is(thead tr th:first-of-type, tbody tr td:first-of-type) {
  position: sticky;
  left: 0;
  /* negative value to cover the background fully */
  transform: translateX(-1px);
}

/* sw prompt animation */

.sw-prompt {
  position: fixed;
  left: 0;
  right: 0;
  width: fit-content;
  margin: 0 auto;
  animation-name: slideUp;
  animation-delay: -0.1s;
  animation-direction: normal;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

@keyframes slideUp {
  from {
    bottom: -4rem;
  }

  to {
    bottom: 4rem;
  }
}

.error {
  color: red;
  font-size: 0.8rem;
}

/* Pagin */

.pagin button {
  transition: transform 0.1s 0s;
  transform: scale(1);
}

.pagin button:hover {
  transform: scale(1.2);
}

.pagin-active-btn {
  pointer-events: none;
}
/* Page number button specific */
.active-page-btn {
  background-color: rgba(248, 135, 79, 1);
  color: white;
}

.inactive-page-btn {
  background-color: white !important;
  color: black !important;
}

/* Icon button */
.icon-btn {
  --size: 1.5rem;
  width: var(--size);
  height: var(--size);
  transform: scale(1);
  transition: transform 0.1s linear;
  border-radius: 50%;
}

.icon-btn:hover {
  transform: scale(1.3);
}

.icon-btn:focus-visible {
  box-shadow: var(--chakra-shadows-outline);
}

.icon-btn > svg {
  width: 100%;
  height: 100%;
}

.icon-btn:hover svg {
  stroke: var(--icon-hover);
}

.icon-btn:active svg {
  stroke: var(--icon-active);
}

/* scale won't work on hover due to shake animation */
.icon-btn.icon-btn.bell,
.icon-btn.icon-btn.bell:hover {
  transform: scale(1);
}

.icon-btn.bell > * {
  transform: scale(1);
  transition: transform 0.1s linear;
}

.icon-btn.bell:hover > * {
  transform: scale(1.3);
}

.shake {
  animation: shake 0.3s linear 2s 3 forwards;
}

@keyframes shake {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(15deg);
  }

  50% {
    transform: rotate(0);
  }

  75% {
    transform: rotate(-15deg);
  }

  100% {
    transform: rotate(0);
  }
}
